@font-face {
    font-family: 'Tiempos Text';
    src: url('../public/fonts/d321f86573f8f5ee-s.p.woff2') format('wolf2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Tiempos Text';
    src: url('../public/fonts/d321f86573f8f5ee-s.p.woff2') format('wolf2');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Tiempos Text';
    src: url('../public/fonts/TiemposText-Bold.otf') format('otf');
    font-weight: bold;
    font-style: normal;
  }